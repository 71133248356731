

document.addEventListener("DOMContentLoaded", function() {
    // logic for hiding/showing the sticky nav background color
    initializeStickyNav('header-home');
    // initializeStickyNav('header-concierge');
    // initializeStickyNav('header-tracklab');

    // logic for main navigation smooth scrolling to anchor links
    const scrollLinks = document.querySelectorAll('.scroll-link');
    const dropdownContainer = document.querySelector('.mobile-dropdown-menu-container');

    scrollLinks.forEach(link => {
        link.addEventListener('click', function (e) {
            e.preventDefault();

            if (dropdownContainer.classList.contains('is-visible')) {
                dropdownContainer.style.height = '0px'; // Initiate collapse
                dropdownContainer.addEventListener('transitionend', function handler() {
                    dropdownContainer.classList.remove('is-visible'); // Remove visibility class
                    dropdownContainer.style.height = ''; // Reset height to default to avoid inline style conflicts
                    dropdownContainer.removeEventListener('transitionend', handler); // Clean up listener
                }, { once: true });
            }


            const href = this.getAttribute('href');
            const targetElement = document.querySelector(href);
            const offset = 90;
            const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset;

            // Smooth scroll to the adjusted position
            window.scrollTo({
                top: elementPosition,
                behavior: 'smooth'
            });

            // Optional: Update the URL hash
            history.pushState(null, null, href);
        });
        if (window.location.hash) {
            const targetElement = document.querySelector(window.location.hash);
            if (targetElement) {
                const offset = 90;
                const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset;

                // Delay the execution to ensure page layout is stable
                setTimeout(() => {
                    window.scrollTo({
                        top: elementPosition,
                        behavior: 'smooth'
                    });
                }, 10);
            }
        }
    });

    //logic for logo click to scroll to top or go to the homepage.
    const logo = document.querySelector('.logo');
    if(!logo){
        return;
    }
    else{
        logo.addEventListener('click', function (e) {
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            history.pushState(null, null, "");
        });
    }


});

// logic for the hamburger menu
document.querySelector('.mobile-dropdown-button').addEventListener('click', function() {
    toggleMenu();
});

function toggleMenu(){
    const mobileContainer = document.querySelector('.mobile-dropdown-menu-container');

    if(mobileContainer.classList.contains('is-visible')) {
        closeMenu(mobileContainer);
    }
    else{
        openMenu(mobileContainer);
    }
}

function openMenu(mobileContainer){
    mobileContainer.classList.add('is-visible');
    const height = mobileContainer.scrollHeight;
    mobileContainer.style.height = '0px';
    setTimeout(() => mobileContainer.style.height = `${height}px`, 10);
}

function closeMenu(mobileContainer){
    const height = mobileContainer.scrollHeight;
    mobileContainer.style.height = `${height}px`;
    setTimeout(() => mobileContainer.style.height = '0px', 0);

    mobileContainer.addEventListener('transitionend', function handler(){
        mobileContainer.classList.remove('is-visible');
        mobileContainer.style.height = '';
        mobileContainer.removeEventListener('transitionend', handler);
    }, {once: true});
}
document.addEventListener('click', function(event){
    const mobileContainer = document.querySelector('.mobile-dropdown-menu-container');
    const dropdownButton = document.querySelector('.mobile-dropdown-button');

    if(mobileContainer.classList.contains('is-visible') && !mobileContainer.contains(event.target) && !dropdownButton.contains(event.target)){
        closeMenu(mobileContainer);
    }
})

document.querySelector('.mobile-dropdown-menu-container').addEventListener('click', function(event){
    event.stopPropagation();
});
function initializeStickyNav(headerId){
    console.log("NAV INIT");
    const header = document.getElementById(headerId);
    const nav = document.querySelector('nav');

    if(!header || !nav){
        return;
    }

    const remInPixels = parseFloat(getComputedStyle(document.documentElement).fontSize);

    const stickyClassVariant = getStickyClassVariant(headerId);

    window.addEventListener('scroll', () => {
        const headerBottom = header.getBoundingClientRect().bottom + window.scrollY - remInPixels*10;
        if (window.scrollY > headerBottom) {
            nav.classList.add(stickyClassVariant);
        } else {
            nav.classList.remove(stickyClassVariant);
        }
    });
}

function getStickyClassVariant(headerId){
    const variants = {
        'header-home': 'sticky-nav-home',
        'header-concierge': 'sticky-nav-concierge',
        'header-tracklab': 'sticky-nav-tracklab'

    }
    return variants[headerId] || 'sticky-nav';
}


