$('.clients-carousel').slick({
    dots: true,
    appendDots: $('.clients-carousel-dots'),
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 5,
    prevArrow: null,
    nextArrow: null,
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true 
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });

  $('.registration-carousel').slick({
    dots: true,
    appendDots: $('.registration-dots'),
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    prevArrow: $('.registration-prev'),
    nextArrow: $('.registration-next'),
  });
// $('.tracklab-carousel').slick({
//     dots: true,
//     appendDots: $('.tracklab-dots'),
//     infinite: false,
//     speed: 300,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     adaptiveHeight: true,
//     prevArrow: $('.tracklab-prev'),
//     nextArrow: $('.tracklab-next'),
// });

$('.inspiration-carousel').slick({
    dots: true,
    appendDots: $('.inspiration-dots'),
    infinite: false,
    adaptiveHeight: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: $('.inspiration-prev'),
    nextArrow: $('.inspiration-next'),
});


  // Initialize Magnific Popup
$('.registration-carousel').magnificPopup({
    delegate: 'a.image-popup', // the selector for gallery item
    type: 'image',
    gallery: {
      enabled:true
    }
});
$('.tracklab-carousel').magnificPopup({
  delegate: 'a.image-popup', // the selector for gallery item
  type: 'image',
  gallery: {
    enabled:true
  }
});
$('.inspiration-carousel').magnificPopup({
    delegate: 'a.image-popup', // the selector for gallery item
    type: 'image',
    gallery: {
        enabled:true
    }
});
